import { default as _91_91page_93_930c5jWXLE6DMeta } from "/app/pages/[[page]].vue?macro=true";
import { default as redirectYgIzTV8UF1Meta } from "/app/pages/auth/[social]/redirect.vue?macro=true";
import { default as contactsyTtBxebE6nMeta } from "/app/pages/info/contacts.vue?macro=true";
import { default as indexXUGwwgbzIMMeta } from "/app/pages/info/index.vue?macro=true";
import { default as privacy_45policyMHgJbA7rv3Meta } from "/app/pages/info/privacy-policy.vue?macro=true";
import { default as TextBlockcUHR5jegRsMeta } from "/app/pages/info/TextBlock.vue?macro=true";
import { default as user_45agreementtuEiuztClhMeta } from "/app/pages/info/user-agreement.vue?macro=true";
import { default as payIPry678w6dMeta } from "/app/pages/orders/[orderId]/pay.vue?macro=true";
import { default as _91id_93kVZKMJ3tbEMeta } from "/app/pages/products/[id].vue?macro=true";
import { default as settingssfrdss5mooMeta } from "/app/pages/profile/settings.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
export default [
  {
    name: _91_91page_93_930c5jWXLE6DMeta?.name ?? "page___ru",
    path: _91_91page_93_930c5jWXLE6DMeta?.path ?? "/:page?",
    meta: _91_91page_93_930c5jWXLE6DMeta || {},
    alias: _91_91page_93_930c5jWXLE6DMeta?.alias || [],
    redirect: _91_91page_93_930c5jWXLE6DMeta?.redirect,
    component: () => import("/app/pages/[[page]].vue").then(m => m.default || m)
  },
  {
    name: redirectYgIzTV8UF1Meta?.name ?? "auth-social-redirect___ru",
    path: redirectYgIzTV8UF1Meta?.path ?? "/auth/:social()/redirect",
    meta: redirectYgIzTV8UF1Meta || {},
    alias: redirectYgIzTV8UF1Meta?.alias || [],
    redirect: redirectYgIzTV8UF1Meta?.redirect,
    component: () => import("/app/pages/auth/[social]/redirect.vue").then(m => m.default || m)
  },
  {
    name: contactsyTtBxebE6nMeta?.name ?? "info-contacts___ru",
    path: contactsyTtBxebE6nMeta?.path ?? "/info/contacts",
    meta: contactsyTtBxebE6nMeta || {},
    alias: contactsyTtBxebE6nMeta?.alias || [],
    redirect: contactsyTtBxebE6nMeta?.redirect,
    component: () => import("/app/pages/info/contacts.vue").then(m => m.default || m)
  },
  {
    name: indexXUGwwgbzIMMeta?.name ?? "info___ru",
    path: indexXUGwwgbzIMMeta?.path ?? "/info",
    meta: indexXUGwwgbzIMMeta || {},
    alias: indexXUGwwgbzIMMeta?.alias || [],
    redirect: indexXUGwwgbzIMMeta?.redirect,
    component: () => import("/app/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyMHgJbA7rv3Meta?.name ?? "info-privacy-policy___ru",
    path: privacy_45policyMHgJbA7rv3Meta?.path ?? "/info/privacy-policy",
    meta: privacy_45policyMHgJbA7rv3Meta || {},
    alias: privacy_45policyMHgJbA7rv3Meta?.alias || [],
    redirect: privacy_45policyMHgJbA7rv3Meta?.redirect,
    component: () => import("/app/pages/info/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: TextBlockcUHR5jegRsMeta?.name ?? "info-TextBlock___ru",
    path: TextBlockcUHR5jegRsMeta?.path ?? "/info/TextBlock",
    meta: TextBlockcUHR5jegRsMeta || {},
    alias: TextBlockcUHR5jegRsMeta?.alias || [],
    redirect: TextBlockcUHR5jegRsMeta?.redirect,
    component: () => import("/app/pages/info/TextBlock.vue").then(m => m.default || m)
  },
  {
    name: user_45agreementtuEiuztClhMeta?.name ?? "info-user-agreement___ru",
    path: user_45agreementtuEiuztClhMeta?.path ?? "/info/user-agreement",
    meta: user_45agreementtuEiuztClhMeta || {},
    alias: user_45agreementtuEiuztClhMeta?.alias || [],
    redirect: user_45agreementtuEiuztClhMeta?.redirect,
    component: () => import("/app/pages/info/user-agreement.vue").then(m => m.default || m)
  },
  {
    name: payIPry678w6dMeta?.name ?? "orders-orderId-pay___ru",
    path: payIPry678w6dMeta?.path ?? "/orders/:orderId()/pay",
    meta: payIPry678w6dMeta || {},
    alias: payIPry678w6dMeta?.alias || [],
    redirect: payIPry678w6dMeta?.redirect,
    component: () => import("/app/pages/orders/[orderId]/pay.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kVZKMJ3tbEMeta?.name ?? "products-id___ru",
    path: _91id_93kVZKMJ3tbEMeta?.path ?? "/products/:id()",
    meta: _91id_93kVZKMJ3tbEMeta || {},
    alias: _91id_93kVZKMJ3tbEMeta?.alias || [],
    redirect: _91id_93kVZKMJ3tbEMeta?.redirect,
    component: () => import("/app/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile___ru",
    path: profileupBlgdy3vLMeta?.path ?? "/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: settingssfrdss5mooMeta?.name ?? "profile-settings___ru",
    path: settingssfrdss5mooMeta?.path ?? "settings",
    meta: settingssfrdss5mooMeta || {},
    alias: settingssfrdss5mooMeta?.alias || [],
    redirect: settingssfrdss5mooMeta?.redirect,
    component: () => import("/app/pages/profile/settings.vue").then(m => m.default || m)
  }
]
  }
]