<template>
  <NuxtLayout>
    <NuxtPage />
    <ClientOnly>
      <Transition name="notification">
        <Notification v-if="notificationsStore.notificationIsRequired" />
      </Transition>
    </ClientOnly>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { getAccess } from "./utils/tokens/getAccess";
import {
  codeFormEventTarget,
  ACTION,
} from "~/components/CodeForm/codeFormEventTarget";

const notificationsStore = useNotificationsStore();
const userStore = useUserStore();

onMounted(() => {
  if (getAccess() && !userStore.userData) {
    try {
      userStore.getUser();
    } catch (e) {
      /* empty */
    }
  }
});

onMounted(() =>
  codeFormEventTarget.addEventListener(ACTION, handleCodeFormEvent),
);

onBeforeUnmount(() =>
  codeFormEventTarget.removeEventListener(ACTION, handleCodeFormEvent),
);

function handleCodeFormEvent() {
  if (userStore.userIsAuthorized) {
    userStore.getUser();
  }
}
</script>

<style lang="scss">
.online-chat-root-TalkMe #supportTrigger {
  @media screen and (max-width: $sm) {
    right: 12px !important;
    bottom: 22px !important;

    svg {
      position: static !important;
    }

    .text.trigger-font-family {
      display: none !important;
    }
  }
}
</style>
