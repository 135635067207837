// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseProduct } from './BaseProduct';
import {
    BaseProductFromJSON,
    BaseProductFromJSONTyped,
    BaseProductToJSON,
} from './BaseProduct';
import type { VoucherCode } from './VoucherCode';
import {
    VoucherCodeFromJSON,
    VoucherCodeFromJSONTyped,
    VoucherCodeToJSON,
} from './VoucherCode';

/**
 * 
 * @export
 * @interface VoucherOrderProduct
 */
export interface VoucherOrderProduct {
    /**
     * 
     * @type {number}
     * @memberof VoucherOrderProduct
     */
    quantity: number;
    /**
     * 
     * @type {BaseProduct}
     * @memberof VoucherOrderProduct
     */
    product: BaseProduct;
    /**
     * 
     * @type {Array<VoucherCode>}
     * @memberof VoucherOrderProduct
     */
    readonly codes: Array<VoucherCode>;
}

/**
 * Check if a given object implements the VoucherOrderProduct interface.
 */
export function instanceOfVoucherOrderProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "codes" in value;

    return isInstance;
}

export function VoucherOrderProductFromJSON(json: any): VoucherOrderProduct {
    return VoucherOrderProductFromJSONTyped(json, false);
}

export function VoucherOrderProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): VoucherOrderProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quantity': json['quantity'],
        'product': BaseProductFromJSON(json['product']),
        'codes': ((json['codes'] as Array<any>).map(VoucherCodeFromJSON)),
    };
}

export function VoucherOrderProductToJSON(value?: VoucherOrderProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quantity': value.quantity,
        'product': BaseProductToJSON(value.product),
    };
}

