/* tslint:disable */
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Question,
} from '../models/index';
import {
    QuestionFromJSON,
    QuestionToJSON,
} from '../models/index';

export interface FaqApiListRequest {
    gamePk: number;
}

/**
 * 
 */
export class FaqApi extends runtime.BaseAPI {

    /**
     */
    async listRaw(requestParameters: FaqApiListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Question>>> {
        if (requestParameters.gamePk === null || requestParameters.gamePk === undefined) {
            throw new runtime.RequiredError('gamePk','Required parameter requestParameters.gamePk was null or undefined when calling list.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/faq/{game_pk}/`.replace(`{${"game_pk"}}`, encodeURIComponent(String(requestParameters.gamePk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(QuestionFromJSON));
    }

    /**
     */
    async list(requestParameters: FaqApiListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Question>> {
        const response = await this.listRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
