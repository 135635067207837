// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    answer: string;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    game: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    order: number;
}

/**
 * Check if a given object implements the Question interface.
 */
export function instanceOfQuestion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "answer" in value;
    isInstance = isInstance && "game" in value;
    isInstance = isInstance && "order" in value;

    return isInstance;
}

export function QuestionFromJSON(json: any): Question {
    return QuestionFromJSONTyped(json, false);
}

export function QuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Question {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'text': json['text'],
        'answer': json['answer'],
        'game': json['game'],
        'order': json['order'],
    };
}

export function QuestionToJSON(value?: Question | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'answer': value.answer,
        'game': value.game,
        'order': value.order,
    };
}

