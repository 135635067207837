// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    BaseRecalculateOrderTyped,
    instanceOfBaseRecalculateOrderTyped,
    BaseRecalculateOrderTypedFromJSON,
    BaseRecalculateOrderTypedFromJSONTyped,
    BaseRecalculateOrderTypedToJSON,
} from './BaseRecalculateOrderTyped';
import {
    BulldropRecalculateOrderTyped,
    instanceOfBulldropRecalculateOrderTyped,
    BulldropRecalculateOrderTypedFromJSON,
    BulldropRecalculateOrderTypedFromJSONTyped,
    BulldropRecalculateOrderTypedToJSON,
} from './BulldropRecalculateOrderTyped';
import {
    MooGoldRecalculateOrderTyped,
    instanceOfMooGoldRecalculateOrderTyped,
    MooGoldRecalculateOrderTypedFromJSON,
    MooGoldRecalculateOrderTypedFromJSONTyped,
    MooGoldRecalculateOrderTypedToJSON,
} from './MooGoldRecalculateOrderTyped';
import {
    PUBGRecalculateOrderTyped,
    instanceOfPUBGRecalculateOrderTyped,
    PUBGRecalculateOrderTypedFromJSON,
    PUBGRecalculateOrderTypedFromJSONTyped,
    PUBGRecalculateOrderTypedToJSON,
} from './PUBGRecalculateOrderTyped';
import {
    StandoffRecalculateOrderTyped,
    instanceOfStandoffRecalculateOrderTyped,
    StandoffRecalculateOrderTypedFromJSON,
    StandoffRecalculateOrderTypedFromJSONTyped,
    StandoffRecalculateOrderTypedToJSON,
} from './StandoffRecalculateOrderTyped';
import {
    SteamRecalculateOrderTyped,
    instanceOfSteamRecalculateOrderTyped,
    SteamRecalculateOrderTypedFromJSON,
    SteamRecalculateOrderTypedFromJSONTyped,
    SteamRecalculateOrderTypedToJSON,
} from './SteamRecalculateOrderTyped';
import {
    VoucherRecalculateOrderTyped,
    instanceOfVoucherRecalculateOrderTyped,
    VoucherRecalculateOrderTypedFromJSON,
    VoucherRecalculateOrderTypedFromJSONTyped,
    VoucherRecalculateOrderTypedToJSON,
} from './VoucherRecalculateOrderTyped';

/**
 * @type RecalculateOrder
 * 
 * @export
 */
export type RecalculateOrder = { resourcetype: 'BulldropOrder' } & BulldropRecalculateOrderTyped | { resourcetype: 'MooGoldOrder' } & MooGoldRecalculateOrderTyped | { resourcetype: 'Order' } & BaseRecalculateOrderTyped | { resourcetype: 'PUBGOrder' } & PUBGRecalculateOrderTyped | { resourcetype: 'StandoffOrder' } & StandoffRecalculateOrderTyped | { resourcetype: 'SteamOrder' } & SteamRecalculateOrderTyped | { resourcetype: 'VoucherOrder' } & VoucherRecalculateOrderTyped;

export function RecalculateOrderFromJSON(json: any): RecalculateOrder {
    return RecalculateOrderFromJSONTyped(json, false);
}

export function RecalculateOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecalculateOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['resourcetype']) {
        case 'BulldropOrder':
            return {...BulldropRecalculateOrderTypedFromJSONTyped(json, true), resourcetype: 'BulldropOrder'};
        case 'MooGoldOrder':
            return {...MooGoldRecalculateOrderTypedFromJSONTyped(json, true), resourcetype: 'MooGoldOrder'};
        case 'Order':
            return {...BaseRecalculateOrderTypedFromJSONTyped(json, true), resourcetype: 'Order'};
        case 'PUBGOrder':
            return {...PUBGRecalculateOrderTypedFromJSONTyped(json, true), resourcetype: 'PUBGOrder'};
        case 'StandoffOrder':
            return {...StandoffRecalculateOrderTypedFromJSONTyped(json, true), resourcetype: 'StandoffOrder'};
        case 'SteamOrder':
            return {...SteamRecalculateOrderTypedFromJSONTyped(json, true), resourcetype: 'SteamOrder'};
        case 'VoucherOrder':
            return {...VoucherRecalculateOrderTypedFromJSONTyped(json, true), resourcetype: 'VoucherOrder'};
        default:
            throw new Error(`No variant of RecalculateOrder exists with 'resourcetype=${json['resourcetype']}'`);
    }
}

export function RecalculateOrderToJSON(value?: RecalculateOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['resourcetype']) {
        case 'BulldropOrder':
            return BulldropRecalculateOrderTypedToJSON(value);
        case 'MooGoldOrder':
            return MooGoldRecalculateOrderTypedToJSON(value);
        case 'Order':
            return BaseRecalculateOrderTypedToJSON(value);
        case 'PUBGOrder':
            return PUBGRecalculateOrderTypedToJSON(value);
        case 'StandoffOrder':
            return StandoffRecalculateOrderTypedToJSON(value);
        case 'SteamOrder':
            return SteamRecalculateOrderTypedToJSON(value);
        case 'VoucherOrder':
            return VoucherRecalculateOrderTypedToJSON(value);
        default:
            throw new Error(`No variant of RecalculateOrder exists with 'resourcetype=${value['resourcetype']}'`);
    }

}

