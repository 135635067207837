import revive_payload_client_K3df1CnxNc from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.5_@unocss+reset@0.59.0_axios@1.6.8_encodi_acwgz3gnuyqswlwy57tiivlqqq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_48Kvv9D7Dj from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.5_@unocss+reset@0.59.0_axios@1.6.8_encodi_acwgz3gnuyqswlwy57tiivlqqq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_blXllF2ztP from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.5_@unocss+reset@0.59.0_axios@1.6.8_encodi_acwgz3gnuyqswlwy57tiivlqqq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_aK6IOMmm3S from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.5_@unocss+reset@0.59.0_axios@1.6.8_encodi_acwgz3gnuyqswlwy57tiivlqqq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_RiRMV4uha8 from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.5_@unocss+reset@0.59.0_axios@1.6.8_encodi_acwgz3gnuyqswlwy57tiivlqqq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_TNGX68pZTY from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.14.0_typescript@5.3.3_vue@3.4.21_typescript@5.3.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_MhHTZ95i3y from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.5_@unocss+reset@0.59.0_axios@1.6.8_encodi_acwgz3gnuyqswlwy57tiivlqqq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_2xQSKrrBrE from "/app/node_modules/.pnpm/vue3-perfect-scrollbar@2.0.0_vue@3.4.21_typescript@5.3.3_/node_modules/vue3-perfect-scrollbar/nuxt/dist/runtime/plugin.mjs";
import i18n_23T7cBjdE0 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.14.0_vue@3.4.21_typescript@5.3.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_wfC1cybS9g from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.5_@unocss+reset@0.59.0_axios@1.6.8_encodi_acwgz3gnuyqswlwy57tiivlqqq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_gen_YFjFjUkJWh from "/app/plugins/api-gen.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import tooltip_client_Mj8x1saCmV from "/app/plugins/tooltip.client.ts";
export default [
  revive_payload_client_K3df1CnxNc,
  unhead_48Kvv9D7Dj,
  router_blXllF2ztP,
  payload_client_aK6IOMmm3S,
  check_outdated_build_client_RiRMV4uha8,
  plugin_vue3_TNGX68pZTY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MhHTZ95i3y,
  plugin_2xQSKrrBrE,
  i18n_23T7cBjdE0,
  chunk_reload_client_wfC1cybS9g,
  api_gen_YFjFjUkJWh,
  sentry_client_shVUlIjFLk,
  tooltip_client_Mj8x1saCmV
]