// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MooGoldServersList } from './MooGoldServersList';
import {
    MooGoldServersListFromJSON,
    MooGoldServersListFromJSONTyped,
    MooGoldServersListToJSON,
} from './MooGoldServersList';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface MooGoldGameDetailTyped
 */
export interface MooGoldGameDetailTyped {
    /**
     * 
     * @type {number}
     * @memberof MooGoldGameDetailTyped
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof MooGoldGameDetailTyped
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MooGoldGameDetailTyped
     */
    detailTitle: string;
    /**
     * 
     * @type {string}
     * @memberof MooGoldGameDetailTyped
     */
    detailDescription: string | null;
    /**
     * 
     * @type {string}
     * @memberof MooGoldGameDetailTyped
     */
    logo: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MooGoldGameDetailTyped
     */
    readonly created: Date;
    /**
     * 
     * @type {boolean}
     * @memberof MooGoldGameDetailTyped
     */
    isAuthRequired: boolean;
    /**
     * 
     * @type {number}
     * @memberof MooGoldGameDetailTyped
     */
    readonly discountPercent: number;
    /**
     * 
     * @type {boolean}
     * @memberof MooGoldGameDetailTyped
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof MooGoldGameDetailTyped
     */
    productPreviewName: string | null;
    /**
     * 
     * @type {string}
     * @memberof MooGoldGameDetailTyped
     */
    productPreviewIcon: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MooGoldGameDetailTyped
     */
    tags: Array<string>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof MooGoldGameDetailTyped
     */
    readonly products: Array<Product>;
    /**
     * 
     * @type {Array<MooGoldServersList>}
     * @memberof MooGoldGameDetailTyped
     */
    readonly servers: Array<MooGoldServersList>;
    /**
     * 
     * @type {string}
     * @memberof MooGoldGameDetailTyped
     */
    resourcetype: string;
}

/**
 * Check if a given object implements the MooGoldGameDetailTyped interface.
 */
export function instanceOfMooGoldGameDetailTyped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "detailTitle" in value;
    isInstance = isInstance && "detailDescription" in value;
    isInstance = isInstance && "logo" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "isAuthRequired" in value;
    isInstance = isInstance && "discountPercent" in value;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "productPreviewName" in value;
    isInstance = isInstance && "productPreviewIcon" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "products" in value;
    isInstance = isInstance && "servers" in value;
    isInstance = isInstance && "resourcetype" in value;

    return isInstance;
}

export function MooGoldGameDetailTypedFromJSON(json: any): MooGoldGameDetailTyped {
    return MooGoldGameDetailTypedFromJSONTyped(json, false);
}

export function MooGoldGameDetailTypedFromJSONTyped(json: any, ignoreDiscriminator: boolean): MooGoldGameDetailTyped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'detailTitle': json['detail_title'],
        'detailDescription': json['detail_description'],
        'logo': json['logo'],
        'created': (new Date(json['created'])),
        'isAuthRequired': json['is_auth_required'],
        'discountPercent': json['discount_percent'],
        'isActive': json['is_active'],
        'productPreviewName': json['product_preview_name'],
        'productPreviewIcon': json['product_preview_icon'],
        'tags': json['tags'],
        'products': ((json['products'] as Array<any>).map(ProductFromJSON)),
        'servers': ((json['servers'] as Array<any>).map(MooGoldServersListFromJSON)),
        'resourcetype': json['resourcetype'],
    };
}

export function MooGoldGameDetailTypedToJSON(value?: MooGoldGameDetailTyped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'detail_title': value.detailTitle,
        'detail_description': value.detailDescription,
        'logo': value.logo,
        'is_auth_required': value.isAuthRequired,
        'is_active': value.isActive,
        'product_preview_name': value.productPreviewName,
        'product_preview_icon': value.productPreviewIcon,
        'tags': value.tags,
        'resourcetype': value.resourcetype,
    };
}

